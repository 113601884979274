<template>
  <b-container>
    <b-row>
      <b-col cols="1"/>
      <b-col>
        <h2>AGB</h2>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="1"/>
      <b-col>
        <br>
        <h3>Datenschutz</h3>
        <p  >Wir nehmen den Schutz Ihrer persönlichen Daten sehr ernst und behandeln Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung.</p>
        <p  >Die Nutzung unserer Website ist ohne Angabe personenbezogener Daten möglich. Soweit auf unseren Seiten personenbezogene Daten (beispielsweise Name, Anschrift oder E-Mail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche Zustimmung nicht an Dritte weitergegeben.</p>
        <p  >Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich. Nachstehende Regelungen informieren Sie insoweit über die Art, den Umfang und Zweck der Erhebung, die Nutzung und die Verarbeitung von personenbezogenen Daten durch den Anbieter.</p>
        <p  >Wenn du bikesharing verwendest, vertraust du uns deine Daten an. Uns ist es sehr wichtig, dich darüber in Kenntnis zu setzen, wie wir deine persönlichen Daten sammeln und nutzen. Die Richtlinien gelten für alle Daten und Informationen, die erfasst werden, wenn du unser Servicenetzwerk verwendest.</p>
        <p  >Wir sammeln oder erhalten Informationen auf unterschiedliche Weise. Die Art der Informationen, die wir sammeln oder erhalten, hängt davon ab, wie Sie unsere Dienste nutzen und mit ihnen interagieren. In vielen Fällen können Sie die Informationen, die Sie uns zur Verfügung stellen, selbst wählen, obwohl bestimmte Informationen für die Bereitstellung der Dienste erforderlich sind. Wir verwenden diese Informationen für die weiter unten beschriebenen Zwecke. Zu den Arten von Informationen, die wir sammeln, gehören.</p>
        <br>
        <h3>Kontoinformationen wie deinen Namen, E-Mail-Adresse, Telefonnummer</h3>
        <p  >Wir erfassen und verarbeiten Standortdaten, wenn Sie sich anmelden und die Dienste nutzen. Um Ihnen zum Beispiel unsere Bikes in der Nähe Ihres Standorts zu zeigen, ist es notwendig, den physischen Standort Ihres Geräts zu erfassen und aufzuzeichnen. Daten wie der Standort des Fahrrads, die Routen, die das Fahrrad zurücklegt, und sein Mietstatus sind ebenfalls notwendig, um die Dienste bereitzustellen. Wir sammeln auch Standortdaten direkt von den Fahrrädern, auch wenn ein Benutzer fährt, wie zum Beispiel Reiseinformationen.</p>
        <p  >Wir sammeln Informationen von Ihrem Browser, Computer oder Mobilgerät, die uns technische Informationen liefern, wie z.B. die Quell-IP-Adresse Ihres Geräts und wann Sie auf die Dienste zugreifen oder diese nutzen. Wir verwenden Cookies und ähnliche Technologien (wie Pixel und Tags), um unsere Dienste bereitzustellen, einschließlich der Speicherung Ihrer Einstellungen und der Erfassung von Analyseinformationen über Sie.</p>
        <br>
        <h3>Wie wir Informationen verwenden</h3>
        <p  >Wir verwenden Ihre Informationen, einschließlich der Informationen über Ihren Standort, um:</p>
        <p  >Ihnen die Dienste auf Ihren Wunsch hin zur Verfügung zu stellen, einschließlich der Verwaltung Ihres Kontos und Ihres Fahrtverlaufs;</p>
        <p  >die Fahrräder von bikeshare zu überwachen, zu verfolgen und zu warten, auch wenn sie von Ihnen benutzt werden;</p>
        <p  >mit Ihnen über Ihr Konto, Interaktionen oder Transaktionen zu kommunizieren, einschließlich dienstleistungsbezogener Ankündigungen wie Änderungen unserer Richtlinien.</p>
        <br>
        <p  >Wie nach örtlichem Recht zulässig, können wir Ihnen auch Umfragen oder Marketingmitteilungen, einschließlich Informationen über Funktionen und Verbesserungen unserer Dienste, zusenden;</p>
        <p  >unsere Dienstleistungen optimieren, entwickeln und verbessern. Zu diesem Zweck können wir Drittanbieter von Analysen heranziehen, um zu verstehen, wie der Dienst genutzt wird, und um uns bei der Verbesserung der Dienste zu unterstützen;</p>
        <p style="margin-bottom: 7rem;">wo dies erforderlich ist, um unseren rechtlichen Verpflichtungen nachzukommen, einschließlich der Erfüllung gesetzlicher oder lokaler Anforderungen.</p>
      </b-col>
      <b-col cols="1"/>
    </b-row>
    <b-row>
      <NextButton text="Weiter" next @click="acceptToS" />
      <div class="box"/>
    </b-row>
  </b-container>
</template>

<script>
  import NextButton from '../components/NextButton';
  export default {
    name: 'ToS',
    components: {
      NextButton,
    },
    methods: {
      acceptToS() {
        // sets tos to accepted in the database
        this.axios.post('/user/acceptSettings', {
            mail: this.$store.state.userProfile.mail,
            setting: 'tos',
            }, {
            headers: {
              token: localStorage.getItem('token')
            }
        })
          .then((res) => {
            if (res.status === 200) {
              this.$store.state.userProfile.accepted.tos = Date.now();
              this.$router.push('/tou')
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    },
    mounted() {
      // skips tos if user already accepted them
      if (this.$store.state.userProfile.accepted.tos) this.$router.push('/tou')
    }
  }
</script>

<style>
  .tos-wrapper {
    height: 78vh;
    width: 100vw;
    margin-top: 12vh;
    margin-bottom: 10vh;
    overflow: scroll;

  }

  .tos-text p {
    margin-left: 3.75rem;
    margin-right: 3.75rem;
    font-size: 10pt;
    overflow: hidden;
  }

</style>